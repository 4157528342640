import { RowModel, flexRender } from '@tanstack/react-table'

import { Tbody, Td, Tr } from '@chakra-ui/react'

import { TableSpinner } from '../../TableUtils'

export const ROW_HEIGHT = 57

interface TableBodyIProps<DataT> {
  isLoading?: boolean
  pageSize?: number
  rowModel: RowModel<DataT>
  rowHeight?: number
  onRowClick?: (id: string) => void
}

export const TableBody = <DataT,>({
  isLoading,
  pageSize,
  rowModel,
  rowHeight = ROW_HEIGHT,
  onRowClick,
}: TableBodyIProps<DataT>) => {
  if (isLoading) {
    return <TableSpinner height={`${pageSize * ROW_HEIGHT}px`} />
  }
  return (
    <Tbody>
      {rowModel?.rows?.map((row) => (
        <Tr
          _hover={{
            bgColor: '#fafafa',
          }}
          cursor='pointer'
          key={row.id}
          onClick={() => {
            const originalObject = row.original as { id: string }
            onRowClick?.(originalObject?.id)
          }}
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='all'
        >
          {row.getVisibleCells().map((cell) => (
            <Td
              color='#838692'
              fontSize='14px'
              fontWeight={500}
              height={rowHeight}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  )
}
